<template>
  <cancellation-review :typePage="typePage" :fieldList="fieldList"></cancellation-review>
</template>

<script>
import CancellationReview from "@/views/modules/collection/business/cancellationReview.vue";

export default {
    name: "auxiliaryExamine",
    components: {CancellationReview},
    data(){
        return{
            fieldList:[
                {
                    "archivesBasicTypeMethodCode": "=",
                    "key": "entryState",
                    "keyName": "",
                    "theKey": "",
                    "value": 2
                },
                {
                    archivesBasicTypeMethodCode: "=",
                    key: "logoffState",
                    theKey: "",
                    value: "1",
                },
            ],
            typePage:'auxiliaryExamine',
        }
    },
}
</script>

<style scoped>

</style>